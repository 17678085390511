
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import dayjs from "dayjs";


export class DayJsDateFormatter extends NgbDateParserFormatter {

  readonly DT_FORMAT = 'MM/DD/YYYY';

  parse(value: string): NgbDateStruct {
    if (value) {
      value = value.trim();
      let mdt = dayjs(value, this.DT_FORMAT)
    }
    return { year: 0, day: 0, month: 0 };
  }

  format(date: NgbDateStruct): string {
    if (!date) return '';
    let mdt = dayjs(`${date.year}-${date.month}-${date.day}`);
    if (!mdt.isValid()) return '';
    return mdt.format(this.DT_FORMAT);
  }
}
