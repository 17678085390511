import { query } from '@angular/animations';
import { Component, Input, ViewChild, inject } from '@angular/core';
import { AuthenticatorService } from '@aws-amplify/ui-angular';
import { Router } from '@angular/router';
import { QueriesService } from '../../services/queries.service';
import { ToastrService } from 'ngx-toastr';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {

  static query: QueriesService;
  static toaster: ToastrService
  @ViewChild('amplify-phone-number-field')
  phoneField: any;

  constructor(public authenticator: AuthenticatorService, private router: Router, private queriesService: QueriesService, private toastr: ToastrService,) {
    LoginComponent.query = queriesService;
    LoginComponent.toaster = toastr;

    this.authenticator.subscribe((data: any) => {

      if (data.authStatus == "authenticated") {
        this.router.navigate(['/']);
      };
      if (data.route == "forceNewPassword") {
      //  this.updateLabels();

      }
    })
  }

  async updateLabels() {
    await this.sleep(300);
    const tags = document.getElementsByTagName('amplify-phone-number-field');
    if (tags?.length > 0) {
      const phoneArea = tags[0];
      if (phoneArea.childNodes?.length > 0) {
        const label = phoneArea.childNodes[0];
        const field = phoneArea.childNodes[1];
        label.textContent = 'Mobile Phone Number';
        console.log(field);
        if (field.childNodes?.length > 1) {
          const input = field.childNodes[1] as any;
          input.placeholder = 'Enter Your Mobile Phone Number';
        }


      }

    }
  }

  signUp() {

  }
  sleep(ms: number) { return new Promise(resolve => setTimeout(resolve, ms)); }
  // services = {

  //   async handleSignUp(formData: Record<string, any>) {
  //     const query = LoginComponent.query;
  //     const toastr = LoginComponent.toaster;
  //     let { username, password, attributes } = formData;
  //     //const source$ = query.getRegistrantByEmail(attributes.email);
  //     try {
  //       //const data = await lastValueFrom(source$);
  //       query.getRegistrantByEmail(attributes.email).subscribe(data => {
  //         console.log(data);

  //       })
  //       const data = await query.getRegistrantByEmail(attributes.email).toPromise();
  //       username = username.toLowerCase();
  //       attributes.email = attributes.email.toLowerCase();
  //       attributes['custom:groupId'] = data.groupId;
  //       attributes['custom:companyId'] = data.companyId;
  //       attributes['custom:roles'] = 'PATIENT';
  //     } catch (e) {
  //       console.log(e);

  //     }
  //     return Auth.signUp({
  //       username,
  //       password,
  //       attributes,
  //       autoSignIn: {
  //         enabled: true,
  //       }
  //     });
  //   }

  // }
}
