import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { QueriesService } from '../../services/queries.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { fetchAuthSession } from 'aws-amplify/auth';


@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss'
})
export class TermsComponent implements OnInit {
  user: any;


  constructor(private authService: AuthService, private queryService: QueriesService, private toastr: ToastrService, private router: Router, private spinner: NgxSpinnerService,) { }

  async ngOnInit(): Promise<void> {
    const ses = await fetchAuthSession();
    this.user = ses.tokens?.idToken?.payload;
  }

  accept() {
    this.spinner.show('acceptSpinner');
    this.queryService.acceptTerms().subscribe({
      next: rep => {
        this.spinner.hide('acceptSpinner');
        if (this.user['custom:role'] == 'PATIENT') {
          this.queryService.updateAsRegistered(this.user['email']).subscribe({
            next: repp => {
              localStorage.setItem('accpetedTerms', 'yes');

                this.router.navigate(['/dashboard']);

            },
            error: err => {
              this.spinner.hide('acceptSpinner');
              this.toastr.error("Sorry, an error occurred updating your account to accepted. Please try again.");
            }
          });
        }else{
          localStorage.setItem('accpetedTerms', 'yes');
            this.router.navigate(['/dashboard']);
        }
      },
      error: err => {
        this.spinner.hide('acceptSpinner');
        this.toastr.error("Sorry, an error occurred updating your account to accepted. Please try again.");
      }
    });
  }

  logout() {
    this.authService.signOut();
  }
}
