{
  "aws_project_region": "us-east-2",
  "aws_cognito_region": "us-east-2",
  "aws_user_pools_id": "us-east-2_VOhVDPxm8",
  "aws_user_pools_web_client_id": "bsn5i5cht9v602npje62bi7vu",
  "oauth": {},
  "aws_cognito_username_attributes": ["EMAIL"],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": ["EMAIL"],
  "aws_cognito_mfa_configuration": "ON",
  "aws_cognito_mfa_types": ["SMS"],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": ["EMAIL"]
}
