import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedEventService {

  isAuthorized = new EventEmitter<boolean>();

  private userDetailSource = new BehaviorSubject<any>(null);
  user = this.userDetailSource.asObservable();
  updateUser(userDetail: any) {
    this.userDetailSource.next(userDetail);
  }

}
