import { Component, OnInit, ViewChild } from '@angular/core';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import amplifyconfig from '../amplifyconfiguration.json';
import dayjs from 'dayjs';
import { sessionStorage } from 'aws-amplify/utils';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {


  constructor() {
    const authConfig: ResourcesConfig['Auth'] = {
      Cognito: {
        userPoolId: amplifyconfig.aws_user_pools_id,
        userPoolClientId: amplifyconfig.aws_user_pools_web_client_id
      }
    };
    cognitoUserPoolsTokenProvider.setAuthConfig(authConfig);
    cognitoUserPoolsTokenProvider.setKeyValueStorage(sessionStorage);

    Amplify.configure({ Auth: authConfig }, { Auth: { tokenProvider: cognitoUserPoolsTokenProvider } });
  }
  ngOnInit(): void {

  }
}
