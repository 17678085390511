<img src="https://test.medsdirectrx.com/assets/top_banner.svg" height="" class="banner" />
<div class="row justify-content-sm-center ">
  <div class="col-12">
    <div class="mx-5 my-5">
      <img src="../../../assets/medsdirect-horizontal.png" alt="logo" width="500" />
    </div>
  </div>
  <div class="row ">
    <div class="col-6 d-none d-sm-block ">
      <div class="d-flex justify-content-end align-items-center">
        <div class="st-rx">
          <img src="../../../assets/main-logowebp.webp" alt="logo" />
        </div>
      </div>
    </div>
    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text">
      <h3>Welcome to MedsDirectRx!</h3>
      <h4>Login</h4>
      <amplify-authenticator [signUpAttributes]="['phone_number']" [hideSignUp]="true">
      </amplify-authenticator>
    </div>
  </div>
</div>
<!-- <img src="https://test.medsdirectrx.com/assets/bottom_banner.svg" height="" class="banner btm" /> -->
