<app-navbar></app-navbar>

<div class="mainArea mt-5">

  <div class="d-flex justify-content-center">

    <div class="terms ">
      <img src="../../../assets/medsdirect-horizontal.png" alt="logo" width="500">
      <br />
      <h1 style="font-style: italic;">Please review and accept</h1>
      <br />
      <div>
        <h2>Privacy Policy</h2>
        <p>This statement outlines the policy and procedures of MedsDirectRx, LLC regarding the collection and use of
          your personal
          information from your visit(s) to our Web site. We collect no information about you, other than information
          automatically collected and stored (see below), when you visit our Web site unless you choose to provide that
          information to us. MedsDirectRx is committed to protecting the privacy of all visitors to our Web site. It is
          our goal
          to maintain compliance with the highest standards of website privacy so our visitors may use the site for
          healthcare
          information needs. MedsDirectRx is committed to making certain that our Web site is used as a resource to
          fulfill our
          mission of serving others. Information gathered on our Web site is used to monitor effectiveness in providing
          relevant
          and credible health information, in answering questions, and in improving our website.</p>
        <p></p>
        <p>

        </p>
        <h2>Information Collected</h2>
        <p>We gather general information to monitor site usage and improve the quality of the website that is not linked
          to any
          personal identifying information. The Web site recognizes and collects the domain name of a visitor’s server
          (for
          example, gmail.com). We do not automatically collect personally identifiable information, such as, name,
          e-mail address,
          physical address, unless you specifically provide that information to us. Examples of how you might provide us
          with such
          personal information include: • Creating a web account to access your MedsDirectRx data; • Completing forms
          such as
          enrollment, a survey or feedback form; • E-mailing us with a comment; • Subscribing to our e-mail newsletter;
          Using
          Information Collected • MedsDirectRx does not sell, share, or release personal information about its website
          visitors to
          any third parties, unless compelled by law. • Personal information provided by you may be used to distribute
          materials
          related to your areas of interest. • The information we collect about the domain name of the server from which
          you are
          visiting is aggregated and used solely to determine the number of visitors to our site each day, and sources
          of traffic.
          • E-mail addresses provided by visitors are used to respond to comments submitted through our feedback and
          surveys
          forms, when indicated. E-mail addresses provided when subscribing to e-mail newsletters are used to distribute
          the
          newsletter via email to subscribers. Information provided by visitors who choose to establish a web account is
          used to
          furnish that page with the data requested by the visitor and to send information requested by the visitor.</p>
        <p>

        </p>
        <h2>Who Has Access to the Information</h2>
        <p>Only MedsDirectRx web team employees have access to information collected from our website. If a visitor
          submits
          additional identifying information, that information is available only to the staff involved in fulfilling
          your
          requested activity.</p>
        <p></p>
        <h2>Cookies</h2>
        <p>“Cookies” are small pieces of information that are stored by your web browser on your computer’s hard drive.
          Cookies are
          used by most Web sites. Cookies can contain information about your use of our site, but they are not used to
          identify or
          track you personally. MedsDirectRx uses cookies to monitor the number of visitors from certain Web sites. Most
          web
          browsers automatically accept cookies, but you can change your browser setting to prevent acceptance of
          cookies. Check
          with your Internet Service Provider for assistance in changing this setting. You should be aware that
          significant
          portions of our Web site will not function properly if you do not accept cookies.</p>
        <p>

        </p>
        <h2>Authorized Use and Your Consent</h2>
        <p>By using our website, you consent to the collection and use of the information discussed above. By entering
          patient
          validating information, you are attesting that you are accessing your personal information or have been
          authorized to
          access the personal information of another. Changes in this policy will be posted on this page so that you may
          always be
          aware of what information is being collected, how it is being used, and under what circumstances it is being
          disclosed.</p>
        <p>

        </p>
        <h2>Accessing other websites through links on this website</h2>
        <p>Our website provides links to websites of agencies and organizations when we believe that the other websites
          may be of
          interest to our visitors. This does not constitute an endorsement of those websites. Once you link to another
          site, you
          are subject to the privacy policy and any other policy of that site.</p>
        <p></p>
        <h2>E-mail Security</h2>
        <p>We offer the opportunity to communicate to health care providers and programs via e-mail. Because normal
          e-mail is not
          encrypted, the possibility exists that unauthorized individuals may intercept e-mail messages with
          MedsDirectRx.
          MedsDirectRx is not responsible for privacy of e-mail messages except those stored in our system.</p>
        <p></p>
        <h2>Our Commitment</h2>
        <p>MedsDirectRx will protect the personal information that you share with us. Our website,
          www.medsdirectrx.com, is
          developed by MedsDirectRx, LLC. Our address is: 8520 Allison Pointe Blvd, Suite 200, Indianapolis IN 46250.
          Our Privacy
          Officer can be contacted by e-mail at scott&#64;medsdirectrx.com or by telephone at 463/888-7403.</p>
      </div>

    </div>


  </div>
  <div class="d-flex justify-content-end">

    <div class="terms mb-5">
      <button type="button" class="btn btn-outline-primary" (click)="accept()">Accept Terms</button>
    </div>
  </div>
</div>

<ngx-spinner name="acceptSpinner" bdColor="rgba(51,51,51,0.0)"  color="#003BA4" [fullScreen]="true" type="ball-scale-pulse" size="medium">
  <h4 class="spinner-text">Accepting Terms</h4>
</ngx-spinner>
