import { TermsGuard } from './auth/terms-guard';
import { AuthGuard } from './auth/auth.guard';
import { NgModule } from '@angular/core';
import { RouterModule, Routes, mapToCanActivate } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { EnrollmentComponent } from './pages/enrollments/enrollment/enrollment.component';
import { GroupsComponent } from './pages/groups/groups.component';
import { PatientsComponent } from './pages/patients/patients.component';
import { NewPatientComponent } from './pages/patients/new-patient/new-patient.component';
import { NewGroupComponent } from './pages/groups/new-group/new-group.component';
import { PayersComponent } from './pages/payers/payers.component';
import { NewPayerComponent } from './pages/payers/new-payer/new-payer.component';
import { ProductsComponent } from './pages/products/products.component';
import { GroupComponent } from './pages/groups/group/group.component';
import { PayerComponent } from './pages/payers/payer/payer.component';
import { PatientComponent } from './pages/patients/patient/patient.component';
import { ProductPricingComponent } from './pages/product-pricing/product-pricing.component';
import { NewProductPricingComponent } from './pages/product-pricing/new-product-pricing/new-product-pricing.component';
import { TermsComponent } from './pages/terms/terms.component';
import { UsersComponent } from './pages/users/users.component';
import { UserComponent } from './pages/users/user/user.component';
import { EnrollmentsComponent } from './pages/enrollments/enrollments.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/admin-layout/admin-layout.module').then(x => x.AdminLayoutModule)
      }]
  },

  {
    path: 'terms',
    component: TermsComponent
  },


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
